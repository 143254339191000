.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label{
  color:orchid
}
label.inner {
  color: #61dafb !important;
}
.codeblock{
  font-family: 'monaco','courier new';
  opacity: 0.87;
  overflow-x: auto;
  overflow-wrap: break-word;
}
.logo img{
  width: 150px;
  height:auto;
  object-fit: cover;
}
.logo-sm img{
  width: 100px;
  height:auto;
  object-fit: cover;
}